import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AudioPage from './pages/AudioPage';

function App() {
	return (
		<div className="min-h-screen w-full overflow-x-hidden overflow-y-auto bg-gradient-to-b from-[rgb(13,39,96)] to-[rgb(56,93,160)]">
			<Router>
				<Routes>
					<Route index path="/*" element={<AudioPage />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
