import React, { useEffect, useState } from 'react';
import {
	AudioText,
	MobileAudioText,
	DentalCareAudio,
	DentalCareImage,
	HealthyWeightAudio,
	HealthyWeightImage,
	LargeBreedAudio,
	LargeBreedImage,
	RehydrateAudio,
	RehydrateImage,
	ShephardsAudio,
	ShephardsImage,
	SmallOodlesAudio,
	SmallOodlesImage,
} from '../assets/assets';

function AudioPage() {
	const [audioList, setAudioList] = useState([]);

	const audioImages = [
		RehydrateImage,
		LargeBreedImage,
		DentalCareImage,
		HealthyWeightImage,
		SmallOodlesImage,
		ShephardsImage,
	];

	const [activeAudioIndex, setActiveAudioIndex] = useState(null);

	const [audioPercentage, setAudioPercentage] = useState('0');

	useEffect(() => {
		setAudioList([
			new Audio(RehydrateAudio),
			new Audio(LargeBreedAudio),
			new Audio(DentalCareAudio),
			new Audio(HealthyWeightAudio),
			new Audio(SmallOodlesAudio),
			new Audio(ShephardsAudio),
		]);
	}, []);

	// When an audio end, reset the audio time and remove the grasyscale on the audio image
	useEffect(() => {
		if (audioList.length) {
			audioList.forEach((audio, index) => {
				audio.addEventListener('ended', () => {
					audioList[index].currentTime = 0;
					setActiveAudioIndex(null);
				});
				audio.addEventListener('timeupdate', () => {
					const percentage = currentTimeAsPercentage(audio.currentTime, audio.duration);
					if (audioPercentage !== percentage)
						setAudioPercentage(currentTimeAsPercentage(audio.currentTime, audio.duration));
				});
			});
		}
	}, [audioList]);

	// On interaction, stop and reset all audios
	const pauseAll = () => {
		audioList.forEach((audio) => {
			if (!audio.paused) {
				audio.pause();
				audio.currentTime = 0;
			}
		});
		setActiveAudioIndex(null);
	};

	// When an audio image is interacted with, reset all audios,
	// then if the audio was playing do nothing, or play the new audio
	const onAudioClick = (index) => {
		setAudioPercentage('0%');

		const paused = audioList[index].paused;
		pauseAll();

		if (paused) {
			audioList[index].play();
			setActiveAudioIndex(index);
		}
	};

	// Get time bar width
	const currentTimeAsPercentage = (currentTime, duration) => {
		const percentage = Math.floor((currentTime / duration) * 100);
		return `${percentage - (percentage % 5) + 5}%`;
	};

	return (
		<div className="flex flex-col items-center gap-8 h-full min-h-screen sm:justify-center sm:gap-8">
			<div
				className={`h-full w-full bg-black/30 absolute top-0 left-0 transition-opacity ${
					activeAudioIndex === null ? 'opacity-0' : ''
				}`}
			/>
			<img className="w-full scale-150 mt-12 sm:hidden brightness-100" src={MobileAudioText} alt="" />
			<img className="hidden sm:block mt-8 scale-125 xl:scale-100" src={AudioText} alt="" />
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:px-8 sm:gap-x-8">
				{audioImages.map((image, index) => (
					<div key={index} className="px-12 sm:px-6 relative">
						<img
							className={`transition-all ${
								index === activeAudioIndex
									? 'scale-110'
									: activeAudioIndex !== null
									? 'brightness-75'
									: ''
							}`}
							onClick={() => onAudioClick(index)}
							src={image}
							alt=""
						/>
						<div
							className={`absolute w-[75%] top-[83%] h-4 left-1/2 -translate-x-1/2 border-2 rounded-lg ${
								audioList[index] && !audioList[index].paused ? 'opacity-100' : 'opacity-0'
							} transition-opacity shadow`}
						>
							{audioList[index] && !audioList[index].paused && (
								<div
									className={`transition-[width] duration-[1200ms] ease-linear h-3 rounded-md bg-white`}
									style={{
										width: audioPercentage,
									}}
								/>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default AudioPage;
